import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  submit(evt) {
    const formData = new FormData(this.element)
    const queryParams = new URLSearchParams(formData).toString()
    const newUrl = `${window.location.pathname}?${queryParams}`
    history.pushState(null, '', newUrl)
    clearTimeout(this.timeout)
    this.element.requestSubmit()
  }
}
